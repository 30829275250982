import { useEffect, useState } from 'react';
import axios from 'axios';
import './Style.css';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from './Nav';

export default function EditCity({ magazineCity }) {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [city, setCity] = useState(magazineCity);
  const { id } = useParams();
  useEffect(() => {
    getCity();
    // eslint-disable-next-line
  }, []);
  function getCity() {

    axios
      .get(`https://adminkafb.pugachova.site/apiCityId/cities/${id}`)
      .then(function (response) {
        setInputs(response.data);
      });
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`https://adminkafb.pugachova.site/apiCity/city/${id}/edit`, inputs);
    navigate('/city');
  };

  return (
    <div>
      <Nav />
      <div className="formBox">
        <h1 className="formTitle">Change city</h1>
        <form onSubmit={handleSubmit}>
          <table cellSpacing="10">
            <tbody>
              <tr>
                <th>
                  <label>City: </label>
                </th>
                <td>
                  <input
                    type="text"
                    name="city"
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="button">Send</button>
        </form>
      </div>
    </div>
  );
}
