import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import { toPng } from 'html-to-image';


const QRCodeGenerator = ({ magazineAddress, magazineCity, magazineNum, logo }) => {
  const windowWidth = window.innerWidth;
  const baseUrl = 'https://feedback.pugachova.site/';
  const qrCodeRef = useRef(null);

  const downloadQRCode = (params) => {

    const qrCodeContainer = qrCodeRef.current;
    const options = {
      pixelRatio: 10, // Увеличьте pixelRatio для улучшения разрешения
    };
    toPng(qrCodeContainer, options)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download =
          params.magazineAddress + ', ' + params.magazineCity + '.png';
        link.click();
      })
      .catch(function (error) {
        console.error('Ошибка при скачивании QR-кода:', error);
      });
  };

  const generateFullUrl = () => {
    return baseUrl + magazineNum;
  };

  return (
    <div className="qrBox">
      <div ref={qrCodeRef}>
        <QRCode
          size={windowWidth < 560 ? 250 : 500}
          value={generateFullUrl()}
          logo={logo}
          fgColor="#474a50"
        />
      </div>

      <button
        className="qrButton"
        onClick={() => downloadQRCode({ magazineAddress, magazineCity })}
      >
        Download the QR code
      </button>
    </div>
  );
};

export default QRCodeGenerator;


