import BarChart from './BarChart';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


function Review({ passwordSuccess }) {
  let now = new Date();
  let nowYear = String(now.getFullYear());
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const reviewForCities = [];
  const [months, setMonths] = useState([]);
  const [dataAllYears, setDataAllYears] = useState([]);
  const [dataForReview, setDataForReview] = useState([]);
  const [data, setData] = useState([]);
  const [cities, setCities] = useState([]);
  const [shopAddress, setShopAddress] = useState([]);
  const [yearsInArray, setYearsInArray] = useState([]);
  const [currentShopAddress, setCurrentShopAddress] = useState([]);
  const [select, setSelect] = useState('all');
  const [selectCity, setSelectCity] = useState('all');
  const [filterStreet, setFilterStreet] = useState('');
  const [street, setStreet] = useState([]);
  const [reviewStatus, setReviewStatus] = useState(true);
  const [year, setYear] = useState(nowYear);
  const [dataset, setDataset] = useState('');
  const [datasetValue, setDatasetValue] = useState('');
  const [label, setLabel] = useState(month);
  const [color, setColor] = useState('rgba(18, 198, 3, 0.3)');
  const [title, setTitle] = useState(nowYear);
  const [choice, setChoice] = useState('choice');
  const [question, setQuestion] = useState({});
  const id = 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp1 = await axios.get(
          'https://adminkafb.pugachova.site/apiAdminka/addresses'
        );
        setShopAddress(resp1.data);

        const filteredAddresses = resp1.data.filter((item) => item.city);
        setCurrentShopAddress(filteredAddresses);

        const resp2 = await axios.get(
          'https://adminkafb.pugachova.site/apiReview/review'
        );
        setDataAllYears(resp2.data);
        const yearsArray = resp2.data
          .map((item) => item.year)
          .reduce((acc, item) => {
            if (acc.includes(item)) {
              return acc;
            }
            return [...acc, item];
          }, [])
          .sort((a, b) => b - a);

        setYearsInArray(yearsArray);

        let f = [];
        for (let i = 1; i <= 12; i++) {
          let a = resp2.data
            .filter((item) => item.year === year && item.month === String(i))
            .reduce(
              (acc, n) => {
                acc.yes += Number(n.yes);
                acc.no += Number(n.no);
                return acc;
              },
              { yes: 0, no: 0 }
            );

          f[i - 1] = a;
        }

        setMonths(f);

        const resp3 = await axios.get(
          `https://adminkafb.pugachova.site/apiChoice/choice/${id}`
        );
        setQuestion(resp3.data);

        setTitle(year);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [year]);
  useEffect(() => {
    setData(
      dataAllYears
        .filter((item) => {
          return item.year === year;
        })
        .map((item) => {

          let h = shopAddress.filter((itemShop) => itemShop.num === item.num);

          item.address = h[0].address;
          item.city = h[0].city;
          return item;
        })
    );
    setDataForReview(
      dataAllYears
        .filter((item) => {
          return item.year === year;
        })
        .map((item) => {
          let h = shopAddress.filter((itemShop) => itemShop.num === item.num);

          item.address = h[0].address;
          item.city = h[0].city;
          return item;
        })
    );
  }, [dataAllYears, shopAddress]);

  useEffect(() => {
    setData(
      dataAllYears
        .filter((item) => {
          return item.year === year;
        })
        .map((item) => {
          let h = shopAddress.filter((itemShop) => itemShop.num === item.num);

          item.address = h[0].address;
          item.city = h[0].city;
          return item;
        })
    );
    setTitle(year);
  }, [dataAllYears]);

  const cityTest = shopAddress
    .map((item) => item.city)
    .reduce((acc, item) => {
      if (acc.includes(item)) {
        return acc;
      }
      return [...acc, item];
    }, []);


  let nik = [];

  useEffect(() => {
    const b = [];
    let s = [];
    for (let i = 0; i < cityTest.length; i++) {
      s = data.filter((item) => {
        let e = item.num;
        let c = shopAddress.filter((item) => item.num === e);

        return c[0].city === cityTest[i];
      });
      reviewForCities[i] = s;
      let r = s.reduce(
        (acc, n) => ((acc.yes += Number(n.yes)), (acc.no += Number(n.no)), acc),
        { yes: 0, no: 0 }
      );

      b[i] = r;
    }

    setCities(b);
    setCurrentShopAddress(shopAddress);
  }, [shopAddress]);

  let fs = [];
  useEffect(() => {
    for (let i = 1; i <= 12; i++) {
      let n = data

        .filter((item) => {
          return item.month === String(i) && item.address === filterStreet;
        })
        .reduce(
          (acc, n) => (
            (acc.yes += Number(n.yes)), (acc.no += Number(n.no)), acc
          ),
          { yes: 0, no: 0 }
        );
      fs[i - 1] = n;
    }
    setStreet(fs);
  }, [filterStreet, data]);
  useEffect(() => {
    setDataset(street.map((item) => (reviewStatus ? item.yes : item.no)));
    setDatasetValue(street);
    setLabel(month);
  }, [street]);

  useEffect(() => {

    if (select === 'all') {
      setDataset(months.map((item) => (reviewStatus ? item.yes : item.no)));
      setDatasetValue(months);
      setCurrentShopAddress(
        shopAddress.filter((item) => {
          return item.city;
        })
      );
      setDataForReview(data);
      setLabel(month);
    } else if (select === 'byCity') {
      setDataset(cities.map((item) => (reviewStatus ? item.yes : item.no)));
      setDatasetValue(cities);
      setCurrentShopAddress(
        shopAddress.filter((item) => {
          return item.city;
        })
      );
      setDataForReview(data);
      setLabel(cityTest);
    } else if (select === 'currentCity') {

      for (let i = 1; i <= 12; i++) {
        nik[i - 1] = data
          .filter((item) => {
            return item.month === String(i) && item.city === selectCity;
          })
          .reduce(
            (acc, n) => (
              (acc.yes += Number(n.yes)), (acc.no += Number(n.no)), acc
            ),
            { yes: 0, no: 0 }
          );
      }

      setDataset(nik.map((item) => (reviewStatus ? item.yes : item.no)));
      setDatasetValue(nik);
      setLabel(month);
      setCurrentShopAddress(
        shopAddress.filter((item) => {
          return item.city === selectCity;
        })
      );
      setDataForReview(data.filter((item) => item.city === selectCity));
    } else if (select === 'currentStreet') {
      setDataset(street.map((item) => (reviewStatus ? item.yes : item.no)));
      setDatasetValue(street);
      setCurrentShopAddress(
        shopAddress.filter((item) => {
          return item.city === selectCity;
        })
      );

      setDataForReview(data.filter((item) => item.address === filterStreet));
      setLabel(month);
    }
  }, [months, cities]);

  useEffect(() => {
    let f = [];
    for (let i = 1; i <= 12; i++) {
      let a = data.filter((item) => {
        return item.month === String(i);
      });

      let b = a.reduce(
        (acc, n) => ((acc.yes += Number(n.yes)), (acc.no += Number(n.no)), acc),
        { yes: 0, no: 0 }
      );

      f[i - 1] = b;
    }

    setMonths(f);

    const b = [];
    let s = [];
    for (let i = 0; i < cityTest.length; i++) {
      s = data.filter((item) => {
        let e = item.num;
        let c = shopAddress.filter((item) => item.num === e);

        return c[0].city === cityTest[i];
      });
      reviewForCities[i] = s;
      let r = s.reduce(
        (acc, n) => ((acc.yes += Number(n.yes)), (acc.no += Number(n.no)), acc),
        { yes: 0, no: 0 }
      );

      b[i] = r;
    }

    setCities(b);
  }, [data]);

  useEffect(() => {
    setGraphData({
      labels: label,
      datasets: [
        {
          label: '',
          data: dataset,
          backgroundColor: [color],
          borderColor: ['rgba(89,89,89, 0.2'],
          borderWidth: '1',
          borderRadius: '5',
          fill: true,
        },
      ],
    });
  }, [dataset, label]);

  const [graphData, setGraphData] = useState({
    datasets: [],
  });

  function changeReviewOnYes() {
    setDataset(datasetValue.map((item) => item.yes));
    setColor('rgba(18, 198, 3, 0.3)');
    setReviewStatus(true);
  }
  function changeReviewOnNo() {
    setDataset(datasetValue.map((item) => item.no));
    setColor('rgba(244, 80, 80, 0.4)');
    setReviewStatus(false);
  }
  const optionData = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: title,
        fontSize: 30,

        font: {
          size: 20,
          style: 'italic',

          family: 'Helvetica Neue',
        },
      },

      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        grid: {
          display: false,
          drawBorder: false,
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          font: {
            size: 20,
          },
          color: 'grey',
        },
        gridLines: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'grey',
        },
      },
    },
  };
  function handleChange(e) {
    if (e.target.value === 'all') {
      setDataset(months.map((item) => (reviewStatus ? item.yes : item.no)));
      setDatasetValue(months);
      setLabel(month);
      setCurrentShopAddress(
        shopAddress.filter((item) => {
          return item.city;
        })
      );
      setChoice('choice');
      setSelect('all');
      setSelectCity('all');
      setDataForReview(data);
    } else if (e.target.value === 'byCity') {
      setDataset(cities.map((item) => (reviewStatus ? item.yes : item.no)));
      setDatasetValue(cities);
      setLabel(cityTest);
      setCurrentShopAddress(
        shopAddress.filter((item) => {
          return item.city;
        })
      );
      setChoice('choice');
      setSelect('byCity');
      setSelectCity('byCity');
      setDataForReview(data);
    } else {
      for (let i = 1; i <= 12; i++) {

        nik[i - 1] = data
          .filter((item) => {
            return item.month === String(i) && item.city === e.target.value;
          })
          .reduce(
            (acc, n) => (
              (acc.yes += Number(n.yes)), (acc.no += Number(n.no)), acc
            ),
            { yes: 0, no: 0 }
          );
      }

      setDataset(nik.map((item) => (reviewStatus ? item.yes : item.no)));
      setDatasetValue(nik);
      setLabel(month);
      setCurrentShopAddress(
        shopAddress.filter((item) => {
          return item.city === e.target.value;
        })
      );
      setChoice('choice');
      setSelect('currentCity');
      setSelectCity(e.target.value);

      setDataForReview(data.filter((item) => item.city === e.target.value));
    }
  }
  function selectChange(e) {
    let m = shopAddress.filter((item) => item.address === e.target.value);

    setChoice(e.target.value);
    setSelectCity(m[0].city);
    setFilterStreet(e.target.value);
    setSelect('currentStreet');
    setCurrentShopAddress(
      shopAddress.filter((item) => {
        return item.city === m[0].city;
      })
    );
    setDataForReview(data.filter((item) => item.address === e.target.value));
    setLabel(month);
  }
  function selectDate(e) {
    setYear(e.target.value);
  }

  return (
    <div className="box">
      <div className="containerBar">
        <div className="menuBar">
          {passwordSuccess ? (
            <Link className="btnAdminka" to="/address">
              Admin
            </Link>
          ) : (
            <Link className="btnAdminka" to="/login">
              Admin
            </Link>
          )}
          <button onClick={changeReviewOnYes}>+</button>
          <button onClick={changeReviewOnNo}>-</button>
        </div>
        <div className="bar">
          <BarChart chartData={graphData} optionData={optionData} />
        </div>
      </div>
      <div className="selectBox">
        <select
          className="selectReview"
          value={selectCity}
          onChange={handleChange}
        >
          <option value="all">all stores for a year</option>
          <option value="byCity">by cities</option>
          {cityTest.map((item, i) => (
            <option key={i}>{item}</option>
          ))}
        </select>
        <select className="selectReview" value={choice} onChange={selectChange}>
          <option value="choice" disabled default>
            choose a store
          </option>
          {currentShopAddress.map((item, i) => (
            <option key={i}>{item.address}</option>
          ))}
        </select>
        <select className="selectDate" onChange={selectDate}>
          {yearsInArray.map((item, i) => (
            <option key={i}>{item}</option>
          ))}
        </select>
      </div>
      <div className="reviewBox">
        {dataForReview
          .filter((item) => (reviewStatus ? item.yes === '1' : item.no === '1'))
          .sort((a, b) => b.id - a.id)
          .map((item, i) => (
            <div className="reviewItems" key={i}>
              <div
                className="reviewItem"
                style={{
                  backgroundColor:
                    item.yes === '1'
                      ? 'rgba(18, 198, 3, 0.3)'
                      : 'rgba(244, 80, 80, 0.4)',
                }}
              >
                {item.city}
              </div>
              <div
                className="reviewItem"
                style={{
                  backgroundColor:
                    item.yes === '1'
                      ? 'rgba(18, 198, 3, 0.3)'
                      : 'rgba(244, 80, 80, 0.4)',
                }}
              >
                {item.address}
              </div>
              <div
                className="reviewItem"
                style={{
                  backgroundColor:
                    item.yes === '1'
                      ? 'rgba(18, 198, 3, 0.3)'
                      : 'rgba(244, 80, 80, 0.4)',
                }}
              >
                {item.created_at}
              </div>
              <div
                className="reviewItem"
                style={{
                  backgroundColor:
                    item.yes === '1'
                      ? 'rgba(18, 198, 3, 0.3)'
                      : 'rgba(244, 80, 80, 0.4)',
                }}
              >
                {item.name ? item.name : '-'}
              </div>
              <div
                className="reviewItem"
                style={{
                  backgroundColor:
                    item.yes === '1'
                      ? 'rgba(18, 198, 3, 0.3)'
                      : 'rgba(244, 80, 80, 0.4)',
                }}
              >
                {item.comment ? item.comment : '-'}
              </div>
              <div
                className="reviewItem"
                style={{
                  backgroundColor:
                    item.yes === '1'
                      ? 'rgba(18, 198, 3, 0.3)'
                      : 'rgba(244, 80, 80, 0.4)',
                }}
              >
                {item.check1 === 'true' ? <p>{question.quest1}</p> : ''}

                {item.check2 === 'true' ? <p>{question.quest2}</p> : ''}

                {item.check3 === 'true' ? <p>{question.quest3}</p> : ''}

                {item.check4 === 'true' ? <p>{question.quest4}</p> : ''}

                {item.check5 === 'true' ? <p>{question.quest5}</p> : ''}
                {item.check1 === 'false' &&
                  item.check2 === 'false' &&
                  item.check3 === 'false' &&
                  item.check4 === 'false' &&
                  item.check5 === 'false' &&
                  '-'}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Review;
