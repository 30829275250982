import { Routes, Route } from 'react-router-dom';
import './App.css';
import CreateAddress from './components/CreateAddress';
import EditAddress from './components/EditAddress';
import ListAddress from './components/ListAddress';
import { useState } from 'react';
import Review from './components/Review';
import UpdateQuest from './components/UpdateQuest';
import QRCodeGenerator from './components/QRCodeGenerator';
import Login from './components/Login';
import logo from './components/logo1.svg';
import CreateNewCity from './components/CreateNewCity';
import ListCity from './components/ListCity';
import EditCity from './components/EditCity';

function App() {
  const [magazineAddress, setMagazineAddress] = useState('');
  const [magazineCity, setMagazineCity] = useState('');
  const [magazineNum, setMagazineNum] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState(false);

  function changePasswordState() {
    setPasswordSuccess(true);
  }

  function changeMagazineAddress(params) {
    setMagazineAddress(params);
  }
  function changeMagazineCity(params) {
    setMagazineCity(params);
  }
  function changeMagazineNum(params) {
    setMagazineNum(params);
  }


  return (
    <div className="App">

      <Routes>
        <Route
          index
          element={
            <Review
              passwordSuccess={passwordSuccess}
            />
          }
        />
        <Route
          path="address"
          element={
            <ListAddress
              changeMagazineAddress={changeMagazineAddress}
              changeMagazineCity={changeMagazineCity}
              changeMagazineNum={changeMagazineNum}
            />
          }
        />
        <Route
          path="city"
          element={
            <ListCity
              changeMagazineCity={changeMagazineCity}
            />
          }
        />
        <Route path="create-address" element={<CreateAddress />} />
        <Route
          path="/address/:id/edit"
          element={<EditAddress magazineCity={magazineCity} />}
        />
        <Route
          path="/city/:id/edit-city"
          element={<EditCity magazineCity={magazineCity} />}
        />
        <Route
          path="qrgen"
          element={
            <QRCodeGenerator
              magazineAddress={magazineAddress}
              magazineCity={magazineCity}
              magazineNum={magazineNum}
              logo={logo}
            />
          }
        />
        <Route path="question" element={<UpdateQuest />} />
        <Route
          path="login"
          element={<Login changePasswordState={changePasswordState} />}
        />
        <Route path="new-city" element={<CreateNewCity />} />
      </Routes>
    </div>
  );
}

export default App;
