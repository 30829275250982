import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import './Style.css';

const CreateNewCity = () => {
  const [info, setInfo] = useState([]);
  useEffect(() => {
    getAddresses();
  }, []);
  let a = info.map((item) => item.num);

  let num = Number(a[a.length - 1]) + 1;
  function getAddresses() {
    axios
      .get('https://adminkafb.pugachova.site/apiCity/cities/')
      .then((response) => {
        setInfo(response.data);
      });
  }

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value, num: num }));
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('https://adminkafb.pugachova.site/apiCity/city/save', inputs)
      .then((response) => {
        if (response.data.status === 0) {
          alert(`${response.data.message} has already been added`);
        } else if (response.data.status === 1) {
          navigate('/city');
        }
      })
      .catch((error) => {
        console.error(error);
        // Обработка ошибки, если не удалось выполнить запрос
      });
  };
  return (
    <div>
      <Nav />
      <div className="formBox">
        <h1 className="formTitle">Add city</h1>
        <form className="formCreateAddress" onSubmit={handleSubmit}>
          <table className="tableCreateMagazine" cellSpacing="10">
            <tbody>
              <tr>
                <th>
                  <label>City: </label>
                </th>
                <td>
                  <input
                    type="text"
                    name="city"
                    onChange={handleChange}
                  ></input>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="button">Create</button>
        </form>
      </div>
    </div>
  );
};

export default CreateNewCity;
