

import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend  } from 'chart.js/auto';

function BarChart({ chartData, optionData }) {
  return (
    <div>
      <Bar
        data={chartData}
        options={optionData}
        
      />
    </div>
  );
}

export default BarChart;
