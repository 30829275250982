import { useEffect, useState } from 'react';
import axios from 'axios';
import './Style.css';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';

export default function CreateAddress() {
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [infoCity, setInfoCity] = useState([]);

  let a = info.map((item) => item.num);

  let num = Number(a[a.length - 1]) + 1475;
  const [inputs, setInputs] = useState();

  useEffect(() => {
    getAddresses();
  }, []);
  function getAddresses() {
    axios
      .get('https://adminkafb.pugachova.site/apiAdminka/addresses/')
      .then((response) => {
        setInfo(response.data);
      });
    axios
      .get('https://adminkafb.pugachova.site/apiCity/cities/')
      .then((response) => {
        setInfoCity(response.data);
      });
  }

  useEffect(() => {
    setInputs((values) => ({ ...values, num: num }));
  }, [info]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post('https://adminkafb.pugachova.site/apiAdminka/address/save', inputs);
    navigate('/address');
  };
  return (
    <div>
      <Nav />
      <div className="formBox">
        <h1 className="formTitle">New store</h1>
        <form className="formCreateAddress" onSubmit={handleSubmit}>
          <table className="tableCreateMagazine" cellSpacing="10">
            <tbody>
              <tr>
                <th>
                  <label align="right">City: </label>
                </th>
                <td>
                  <select
                    className="select"
                    name="city"
                    onChange={handleChange}
                  >
                    <option default></option>
                    {infoCity.map((item, i) => (
                      <option key={i}>{item.city}</option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <th>
                  <label>Address: </label>
                </th>
                <td>
                  <input
                    type="text"
                    name="address"
                    onChange={handleChange}
                  ></input>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="button">Create</button>
        </form>
      </div>
    </div>
  );
}
