import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  function changeMenuState() {
    setMenuOpen(!menuOpen);
  }

  return (
    <div className="navBox">
      <button className="menuBtn" onClick={changeMenuState}>
        <span></span>
        <span></span>
        <span></span>
      </button>
      <nav className={`menuItems ${menuOpen ? 'menuActive' : ''}`}>
        <NavLink activeClassName="active" to="/">
          Schedule
        </NavLink>

        <NavLink activeClassName="active" to="/address">
          Stores
        </NavLink>
        <NavLink
          activeClassName="active"

          to="/city"
        >
          Cities
        </NavLink>
        <NavLink activeclassnamee="active" to="/question">
          Survey
        </NavLink>

        <NavLink activeClassName="active" to="/create-address">
          Add store
        </NavLink>
        <NavLink
          activeClassName="active"
          to="/new-city"
        >
          Add city
        </NavLink>
      </nav>
    </div>
  );
};

export default Nav;
