import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from './Nav';
import editImg from './edit.svg';

export default function ListCity({
  changeMagazineCity,
}) {
  const [info, setInfo] = useState([]);
  useEffect(() => {
    getCity();
  }, []);
  function getCity() {
    axios
      .get('https://adminkafb.pugachova.site/apiCity/cities/')
      .then((response) => {
        setInfo(response.data);
      });
  }

  return (
    <>
      <Nav />
      <div className="formBox">
        <table className="addressTable">
          <thead>
            <tr>
              <th>№</th>
              <th>City</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            {info.map((item, key) => (
              <tr key={key}>
                <td>{item.num}</td>
                <td>{item.city}</td>

                <td>
                  <NavLink
                    to={`/city/${item.id}/edit-city`}
                    className="shop__infoLink"
                    onClick={() => {
                      changeMagazineCity(item.city);
                    }}
                  >
                    <img className="editImg" src={editImg} alt="edit"></img>
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
