import axios from 'axios';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Nav from './Nav';
import editImg from './edit.svg';
import qrImg from './qr.svg';

export default function ListAddress({
  changeMagazineAddress,
  changeMagazineCity,
  changeMagazineNum,
}) {
  const [info, setInfo] = useState([]);
  useEffect(() => {
    getAddresses();
  }, []);
  function getAddresses() {
    axios
      .get('https://adminkafb.pugachova.site/apiAdminka/addresses/')
      .then((response) => {
        setInfo(response.data);
      });
  }
  

  return (
    <>
      <Nav />
      <div className="formBox">
        <table className="addressTable">
          <thead>
            <tr>
              <th>City</th>
              <th>Address</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {info.map((item, key) => (
              <tr key={key}>
                <td>{item.city}</td>
                <td>{item.address}</td>
                <td>
                  <NavLink
                    to={`/address/${item.id}/edit`}
                    className="shop__infoLink"
                    onClick={() => {
                      changeMagazineCity(item.city);
                    }}
                  >
                    <img className="editImg" src={editImg} alt="edit"></img>
                  </NavLink>
                </td>
                <td>
                  <NavLink
                    to="/qrgen"
                    className="qr__infoLink"
                    onClick={() => {
                      changeMagazineAddress(item.address);
                      changeMagazineCity(item.city);
                      changeMagazineNum(item.num);
                    }}
                    style={{ textDecoration: 'none' }}
                  >
                    <img className="qrImg" src={qrImg} alt="QR"></img>
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}


