import { useEffect, useState } from 'react';
import axios from 'axios';
import './Style.css';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from './Nav';

export default function EditAddress({ magazineCity }) {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [address, setAddress] = useState('');
  const [infoCity, setInfoCity] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    getAddresses();
    // eslint-disable-next-line
  }, []);
  function getAddresses() {
    axios
      .get(`https://adminkafb.pugachova.site/apiAdminka/address/${id}`)
      .then(function (response) {
        setInputs(response.data);

        setAddress(response.data.address);
      });
    axios
      .get('https://adminkafb.pugachova.site/apiCity/cities/')
      .then((response) => {
        setInfoCity(response.data);
      });
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(
        `https://adminkafb.pugachova.site/apiAdminka/address/${id}/edit`,
        inputs
      );
    navigate('/address');
  };

  return (
    <div>
      <Nav />
      <div className="formBox">
        <h1 className="formTitle">Change address</h1>
        <form onSubmit={handleSubmit}>
          <table cellSpacing="10">
            <tbody>
              <tr>
                <th>
                  <label>Address: </label>
                </th>
                <td>
                  <input
                    className="inputAddress"
                    type="text"
                    name="address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </td>
              </tr>
              <tr>
                <th>
                  <label align="right">City: </label>
                </th>
                <td>
                  <select
                    className="select"
                    value={magazineCity}
                    name="city"
                    onChange={handleChange}
                  >
                    {infoCity.map((item, i) => (
                      <option value={item.city} name={item.city} key={i}>
                        {item.city}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="button">Send</button>
        </form>
      </div>
    </div>
  );
}
