import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import './Style.css';

const Login = ({ changePasswordState }) => {
  const [userName, setUserName] = useState('');
  const [p, setP] = useState('');

  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
    navigate('/address');
    changePasswordState();


    // if (userName === 'admin') {
    //   if (p === 'A345*') {
    //     navigate('/address');
    //     changePasswordState();
    //   } else {
    //     alert('Введіть коректний пароль');
    //   }
    // } else {
    //   if (p === 'A345*') {
    //     alert('Введіть коректний логин');
    //   } else {
    //     alert('Введіть коректний логин і пароль');
    //   }
    // }

    setUserName('');
    setP('');
  }
  return (
    <div className="d-flex vh-100 justify-content-center containerForm">
      <div className="formBox loginForm">
        <h1 className="formTitle">Authentication</h1>
        <form action="" onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              className="form-control inputActive"
              type="text"
              id="name"
              value={userName}
              placeholder="username"
              autoComplete="off"
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <input
              className="form-control inputActive"
              type="password"
              id="p"
              value={p}
              placeholder="password"
              autoComplete="off"
              onChange={(e) => setP(e.target.value)}
            />
          </div>
          <button className="btn btn-success">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
