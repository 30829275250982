import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Style.css';
import Nav from './Nav';

const UpdateQuest = () => {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({});
  const [quest1, setQuest1] = useState('');
  const [quest2, setQuest2] = useState('');
  const [quest3, setQuest3] = useState('');
  const [quest4, setQuest4] = useState('');
  const [quest5, setQuest5] = useState('');

  const id = 1;

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, []);
  function getQuestions() {
    axios
      .get(`https://adminkafb.pugachova.site/apiChoice/choice/${id}`)
      .then(function (response) {
        setInputs(response.data);
        setQuest1(response.data.quest1);
        setQuest2(response.data.quest2);
        setQuest3(response.data.quest3);
        setQuest4(response.data.quest4);
        setQuest5(response.data.quest5);
      });
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(
        `https://adminkafb.pugachova.site/apiChoice/choice/${id}/edit`,
        inputs
      )
      .then(function (response) {
        navigate('/address');
      });
  };
  return (
    <div>
      <Nav />
      <div className="formBox">
        <h1 className="formTitle">Change questions</h1>
        <form className="formQuestion" onSubmit={handleSubmit}>
          <table cellSpacing="10">
            <tbody>
              <tr>
                <th>
                  <label>1: </label>
                </th>
                <td>
                  <input
                    className="inputQuest"
                    type="text"
                    name="quest1"
                    value={quest1}
                    onChange={(e) => {
                      setQuest1(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </td>
              </tr>
              <tr>
                <th>
                  <label align="right">2: </label>
                </th>
                <td>
                  <input
                    className="inputQuest"
                    type="text"
                    name="quest2"
                    value={quest2}
                    onChange={(e) => {
                      setQuest2(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </td>
              </tr>
              <tr>
                <th>
                  <label>3: </label>
                </th>
                <td>
                  <input
                    className="inputQuest"
                    type="text"
                    name="quest3"
                    value={quest3}
                    onChange={(e) => {
                      setQuest3(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </td>
              </tr>
              <tr>
                <th>
                  <label>4: </label>
                </th>
                <td>
                  <input
                    className="inputQuest"
                    type="text"
                    name="quest4"
                    value={quest4}
                    onChange={(e) => {
                      setQuest4(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </td>
              </tr>
              <tr>
                <th>
                  <label>5: </label>
                </th>
                <td>
                  <input
                    className="inputQuest"
                    type="text"
                    name="quest5"
                    value={quest5}
                    onChange={(e) => {
                      setQuest5(e.target.value);
                      handleChange(e);
                    }}
                  ></input>
                </td>
              </tr>
            </tbody>
          </table>
          <button className="button">Send</button>
        </form>
      </div>
    </div>
  );
};

export default UpdateQuest;
